import request from '@/utils/request';

const userApi = {
  partList: '/api/module_total',
  // partListShift: '/api/module_shift', //新增班次查询方式
};

// 部件-部件列表  ApiType
export function reqPartList(parameter) {
  return request({
    url: userApi.partList,
    method: 'post',
    data: parameter,
  });
}

// // 部件-部件列表  ApiType ：指定班次
// export function reqPartListShift(parameter) {
//   return request({
//     url: userApi.partListShift,
//     method: 'post',
//     data: parameter,
//   });
// }
