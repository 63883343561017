<template>
  <div class="box-wrap">
    <div class="top-box">
      <date-search @onsearch="handleSearch"></date-search>
	  <top-date :deviceNum="deviceNum"></top-date>
    </div>
    <div class="chart-box">
      <!-- 顶部2个 -->
      <div class="top-ctn">
        <div class="left-ctn">
          <div class="left-top">
            <div class="l-title">
              <img
                src="@/assets/img/page/page3_pic1.png"
                alt
                class="icon"
              />生产数量 Output
            </div>
            <dv-digital-flop
              class="count"
              :config="
                handleDigitalFlop(topInfo.finish_count_toa, 34, '#FCF4EF')
              "
            />
          </div>
          <div class="left-bottom">
            <div class="count-item">
              <div class="name">目标生产</div>
              <div class="text">Production target</div>
              <dv-digital-flop
                class="count"
                :config="
                  handleDigitalFlop(topInfo.target_count_toal, 20, '#fff')
                "
              />
            </div>
            <div class="count-item">
              <div class="name">完成率</div>
              <div class="text">Complete ratio</div>
              <div class="count">
                {{ handleCountComputed(topInfo.finishing_rate) }}%
              </div>
            </div>
          </div>
        </div>

        <div class="right-ctn">
          <div class="r-item">
            <img src="@/assets/img/page/page3_pic2.png" class="img" alt />
            <div class="count">{{ topInfo.take_time_toal }}</div>
            <div class="text">累计用时 Total time</div>
          </div>
          <div class="r-item">
            <img src="@/assets/img/page/page3_pic3.png" class="img" alt />
            <div class="count">{{ topInfo.defective_sat_count_toal }}</div>
            <div class="text">累计不良数 Total defective quantity</div>
          </div>
        </div>
      </div>
      <!-- 图表 -->
      <div class="table-box">
        <div class="input-box">
          <a-form layout="inline">
            <a-row :gutter="20">
              <a-col :md="4" :sm="4">
                <a-form-item>
                  <a-input
                    size="large"
                    style="width: 220px"
                    v-model="queryParam.ModuleName"
                    @focus="handleFocuse(1)"
                    placeholder="部件查询 Component"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="4" :sm="4">
                <a-form-item>
                  <a-input-search
                    size="large"
                    style="width: 240px; margin: 0 0 0 -9px"
                    @focus="handleFocuse(2)"
                    v-model="queryParam.StyleName"
                    placeholder="型体查询 Model"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="4">
                <div
                  class="table-page-search-submitButtons search-btn-box"
                  style="margin: 0"
                >
                  <div class="warning" @click="getList">查询 Query</div>
                  <div class="warning border" @click="resetTable">
                    重置 Reset
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="table-ctn">
          <a-table
            :columns="columns"
            :data-source="data"
            ref="tableList"
            :row-key="(r, i) => JSON.stringify(r)"
            :pagination="false"
            :scroll="{ y: 550 }"
          >
            <!-- :pagination="tablePageConfig"
           @change="tablePageChange"
           -->
            <!-- <template slot="index" slot-scope="text, record, index">
              {{
                (tablePageConfig.current - 1) * tablePageConfig.pageSize +
                index +1
              }}
            </template> -->
            <template slot="index" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
            <a slot="finish_rate" slot-scope="text, record">
              <div class="text">
                {{ finishRate(record.finish_count, record.target_count) }}%
              </div>
              <div class="line">
                <div
                  class="progress"
                  :style="
                    'width:' +
                    finishRate(record.finish_count, record.target_count) +
                    '%'
                  "
                ></div>
              </div>
            </a>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqPartList } from '@/api/partsPage.js';
import { reqMachineList } from '@/api/index';
import dateSearch from '@/components/dateSearch.vue';
import topDate from '@/components/topDate.vue';
import { formatSeconds } from '@/utils/index.js';
const columns = [
  {
    title: '序号 No',
    scopedSlots: { customRender: 'index' },
    width: 90,
  },
  {
    title: '部件 Component',
    dataIndex: 'module_no',
    width: 135,
  },
  {
    title: '型体 Model',
    dataIndex: 'style_no',
    width: 150,
  },
  {
    title: '计划数量 Plan qty : 片 Piece',
    dataIndex: 'target_count',
    width: 208,
    ellipsis: true,
  },
  {
    title: '完成数量 Complete qty : 片 Piece',
    dataIndex: 'finish_count',
    width: 239,
    ellipsis: true,
  },
  {
    title: '用时 Time (min)',
    dataIndex: 'take_time',
    ellipsis: true,
    width: 133,
  },
  {
    title: '不良数 Defective quantity',
    dataIndex: 'defective_sat_count',
    width: 190,
    ellipsis: true,
  },
  {
    title: '完成率 Complete ratio',
    dataIndex: 'finish_rate',
    width: 170,
    scopedSlots: { customRender: 'finish_rate' },
    ellipsis: true,
  },
  {
    title: '裁切设备 Machine',
    dataIndex: 'machine_name',
    ellipsis: false,
    width: 180,
  },
];

export default {
  components: {
    dateSearch,
    topDate,
  },
  data() {
    return {
      topInfo: {
        defective_sat_count_toal: 0,
        finish_count_toa: 0,
        finishing_rate: 0,
        take_time_toal: 0,
        target_count_toal: 0,
      },
      data: [],
      columns,
      size: 'large',
      dateType: 0, // 0:当天  1：当周  2：当月 3:自定义
      queryParam: {
        ModuleName: '',
        StyleName: '',
        BeginTime: '',
        EndTime: '',
		Shift: '',
        // InstructName: "",
      },
      ApiType: '', //0:指令查询  1：形体查询
      tablePageConfig: {
        // 总数据
        total: 0,
        // 每页条数
        pageSize: 10,
        // 当前页
        current: 1,
        // 显示多少页
        showTotal: (total) => `共有Total ${total} 条 item`,
        // 是否显示跳转页面
        // showQuickJumper: true,
        // 是否开启分页数据条数
        // showSizeChanger: true,
        // 分页每页显示条数
        // pageSizeOptions: [],
      },
      timer: null,
      deviceNum: ' ', //设备数
    };
  },
  mounted() {
    this.dateType = this.$store.state.searchType;
    this.queryParam.BeginTime = this.$store.state.startTimes;
    this.queryParam.EndTime = this.$store.state.endTimes;
	this.queryParam.Shift = this.$store.state.shift;
    this.getList();
    this.getDeviceNum();

    let _this = this;
    _this.timer = setInterval(function () {
      _this.getList();
      _this.getDeviceNum();
    }, 60000);
  },
  computed: {},
  methods: {
    getDeviceNum() {
      let params = {
        DateType: this.dateType,
        MachineNO: '',
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
      };
      reqMachineList(params).then((res) => {
        if (res.Code == 0) {
          this.deviceNum = res.Data.MachineTotals.length;
        }
      });
    },
    handleDigitalFlop(num, size, color) {
      let config1 = {
        number: [num],
        content: '{nt}',
        textAlign: 'left',
        style: {
          LineCap: 'square',
          fill: color,
          fontSize: size,
          fontWeight: 'bold',
        },
      };
      return config1;
    },
    handleFocuse(flg) {
      // 1:部件查询  2：形体查询
      if (flg == 1) {
        // this.queryParam.StyleName = "";
        this.ApiType = 0; //0:指令查询  1：形体查询
      } else {
        this.ApiType = 1;
        // this.queryParam.ModuleName = "";
      }
    },
    finishRate(n, tn) {
      if (n == 0 && tn == 0) {
        return 0;
      }
      return ((n / tn) * 100).toFixed(2);
    },
    handleCountComputed(num) {
      if (num) {
        let count = Number(num).toFixed(2);
        return count;
      } else {
        return 0;
      }
    },
    tablePageChange(pagination) {
      // 分页
      this.tablePageConfig.current = pagination.current;
      this.getList();
    },
    handleSearch(val) {
      if (val) {
        this.dateType = val.dateType;
        this.queryParam.BeginTime = val.start_time;
        this.queryParam.EndTime = val.end_time;
		this.queryParam.Shift = val.shift;
      }
      this.tablePageConfig.total = 0;
      this.tablePageConfig.current = 1;
      this.data = [];
      this.getList();
    },
    getList() {
      let params = {
        ModuleName: this.queryParam.ModuleName,
        StyleName: this.queryParam.StyleName,
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift,
        ApiType: this.ApiType,
        DateType: this.dateType,
        PageNo: this.tablePageConfig.current,
        PageSize: this.tablePageConfig.pageSize,
      };

      reqPartList(Object.assign(params, this.queryParam)).then((res) => {
        if (res.Code == 0) {
          //this.data = res.Data.ModuleTotals;
          this.data = res.Data.ModuleTotals.map((item) => ({
            ...item,
            take_time: (item.take_time / 60).toFixed(2), // 转换为分钟并保留两位小数
          }));
          // this.tablePageConfig.total = res.Data.ModuleTotals_Size;
          this.topInfo = {
            defective_sat_count_toal: res.Data.total_defective_sat_count || 0,
            finish_count_toa: res.Data.total_finish_count || 0,
            finishing_rate: res.Data.total_finish_rate * 100 || 0,
            take_time_toal: formatSeconds(res.Data.total_take_time) || 0,
            target_count_toal: res.Data.total_target_count || 0,
          };
        }
      });
    },
    resetTable() {
      this.queryParam.ModuleName = '';
      this.queryParam.StyleName = '';
      this.ApiType = '';
      this.tablePageConfig.total = 0;
      this.tablePageConfig.current = 1;
      this.data = [];
      this.getList();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.box-wrap {
  width: 100%;
  height: 100%;
  background: #f4f5f7;
  overflow: hidden;
  .top-box {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
  }
  .chart-box {
    height: 1020px;
    .top-ctn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      padding: 22px 30px 11px;
      .left-ctn {
        width: 534px;
        height: 231px;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
        background-image: url('../assets/img/page/page3_bg1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .left-top {
          height: 115px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .l-title {
            height: 24px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
            padding-right: 80px;
            .icon {
              width: 14px;
              height: 11px;
              margin-right: 5px;
            }
          }
          .count {
            min-width: 50px;
            height: 50px;
            line-height: 50px;
            color: #fcf4ef;
            font-size: 34px;
            font-weight: bold;
          }
        }
        .left-bottom {
          // width: 204px;
          width: 230px;
          display: flex;
          height: 115px;
          align-items: center;
          .count-item {
            width: 115px;
            .text {
              color: rgba(255, 255, 255, 0.8);
              font-size: 12px;
            }
            .name {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.8);
              margin-bottom: 4px;
              height: 20px;
              position: relative;
              &::after {
                content: '';
                position: absolute;
                left: 0px;
                bottom: 0;
                width: 28px;
                height: 2px;
                background: rgba(255, 255, 255, 0.3);
              }
            }
            .count {
              font-size: 20px;
              color: #ffffff;
              height: 30px;
              line-height: 30px;
              font-weight: bold;
            }
          }
        }
      }
      .right-ctn {
        width: 1054px;
        height: 224px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../assets/img/page/page3_bg2.png');
        background-repeat: no-repeat;
        background-size: 110% 110%;
        background-position: 10%;
        .r-item {
          flex: 1;
          align-items: baseline;
          justify-content: center;
          text-align: center;
          display: flex;
          &:first-child {
            position: relative;
            &::after {
              content: '';
              width: 2px;
              height: 81px;
              position: absolute;
              right: 0;
              top: 0;
              background: #eaeaea;
            }
          }
          .img {
            width: 38px;
            height: 38px;
            display: block;
          }
          .count {
            font-size: 34px;
            height: 26px;
            line-height: 30px;
            color: #7c828d;
            font-weight: bold;
            padding: 0 10px;
          }
          .text {
            font-size: 14px;
            color: #a8adb3;
          }
        }
      }
    }
    // 表格
    .table-box {
      margin: 11px 30px 0 35px;
      width: 1602px;
      border-radius: 10px;
      height: 750px;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
      background: #ffffff;
      padding: 26px 36px;
      .search-btn-box {
        width: 240px;
        padding: 2.5px 0 0 0;
        display: flex;
      }
      .input-box {
        padding: 1px 0 0 0;
        //background-color: #7c828d;
      }
      .warning {
        width: 200px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        text-align: center;
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
        background: #fd870a;

        box-shadow: 0px 4px 7px 0px rgba(253, 135, 10, 0.3);
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
      }
      .border {
        border: 1px solid #ddd;
        background: #fff;
        color: #999;
      }

      .table-ctn {
        margin-top: 16px;
      }

      .text {
        font-size: 14px;
        color: #808792;
      }
      .line {
        width: 100px;
        height: 10px;
        border-radius: 49px;
        background: #eee9e4;
        position: relative;
        overflow: hidden;
      }
      .progress {
        height: 10px;
        border-radius: 49px;
        background: linear-gradient(270deg, #ff7738 0%, #ffb038 100%);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
